<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">主題區</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                主題區
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <div class="card">
          <div class="card-body border-top-5 px-3 rounded border-warning">
            <h3 class="card-title text-warning mb-5">{{ article.title }}</h3>
            <ul class="list-unstyled d-flex mb-5">
              <li class="">
                <a class="text-muted d-inline-block mr-3" href="#author"><i class="fa fa-user mr-2" aria-hidden="true"></i>{{ article.author }}</a>
              </li>
              <li class="mr-3">
                <a class="text-muted d-inline-block" href="#post-time"><i class="fa fa-clock-o mr-2" aria-hidden="true"></i>{{ article.published_at }}</a>
              </li>
              <li class="mx-2">
                <FBShareButton />
              </li>
              <li class="mx-2">
                <LineShareButton />
              </li>
            </ul>
            <div class="embed-responsive embed-responsive-16by9 mb-6">
              <iframe class="embed-responsive-item" :src="`https://www.youtube.com/embed/${article.videoCode}`" allowfullscreen></iframe>
            </div>
            <p class="card-text text-justify mb-6" v-html="article.content"></p>
          </div>
        </div>
        <section class="pt-9 py-md-7" style="display: none;">
          <div class="container">
            <div class="section-title justify-content-center mb-8 wow fadeInUp">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">相關影音</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12" v-for="video in list" :key="video.id">
                <div class="card">
                  <a href="course-single-left-sidebar.html" class="position-relative">
                    <img class="card-img-top" :src="video.banner" alt="Card image">
                    <div class="card-img-overlay">
                      <span :class="`badge-${video.themeClass}`" class="badge badge-rounded-circle"><i class="fa fa fa-film" aria-hidden="true"></i></span>
                    </div>
                  </a>
                  <div :class="`border-${video.themeClass}`" class="card-body border-top-5 px-3 rounded-bottom">
                    <h3 class="card-title">
                      <a :class="`text-${video.themeClass}`" class="text-capitalize d-block text-truncate" href="course-single-left-sidebar.html">{{ video.title }}</a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="w-100" v-if="list.length === 0">
                <h2 class="text-primary text-center">目前尚無相關影音文章</h2>
              </div>
            </div>
          </div>
        </section>
        <section class="pt-9 py-md-7">
          <div class="container">
            <div class="section-title justify-content-center mb-8 wow fadeInUp">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">來～我們一起玩遊戲！</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div class="row wow fadeInUp">
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">
                  <div class="card bg-success card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-success text-success pt-6">
                        <i class="fa fa-leaf font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財幼幼級</h2>
                      <a href="#teachers" class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">
                  <div class="card bg-info card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-info text-info pt-6">
                        <i class="fa fa-futbol-o font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國小級</h2>
                      <a href="#blog" class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">
                  <div class="card bg-danger card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-danger text-danger pt-6">
                        <i class="fa fa-gamepad font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國中級</h2>
                      <a href="#gallery" class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">
                  <div class="card bg-warning card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-warning text-warning pt-6">
                        <i class="fa fa-graduation-cap font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財高中級</h2>
                      <a href="#courses" class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import FBShareButton from '@/components/FBShareButton.vue'
import LineShareButton from '@/components/LineShareButton.vue'
export default {
  name: 'video',
  components: { FBShareButton, LineShareButton },
  data () {
    return {
      article: {},
      list: [],
      categoryId: []
    }
  },
  created () {
    const vm = this
    vm.getData()
    vm.getSecData()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getData () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/detail`
      const id = vm.$route.params.id

      vm.axios.post(api, {
        token: tokenGenerator(),
        id
      })
        .then((res) => {
          console.log(res)
          vm.article = res.data.content
          vm.categoryId.push(res.data.content.categoryId)
        })
    },
    getSecData () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.axios.post(api, {
        token: tokenGenerator(),
        type: ['3', '2'],
        categoryId: vm.categoryId,
        pageSize: 2,
        page: 1
      })
        .then((res) => {
          vm.list = res.data.content
          vm.list.forEach((item, index) => {
            const i = index + 1
            if (i % 2 === 1) {
              return (item.themeClass = 'warning')
            } else {
              return (item.themeClass = 'success')
            }
          })
        })
    },
    checkGradeIsAvaliable (e, grade, link) {
      /**
       * 點擊任一連結時打 check api，如果未登入就導向登入頁
       * 如果已登入，確認是否可玩該區塊遊戲
       * 如果可以就 push 進去
       * 如果不行就維持在此頁
       */
      const memberToken = localStorage.getItem('memberToken')
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      if (!memberToken) {
        alert('尚未登入，請先登入之後才能開始遊戲~')
        vm.$router.push('/login')
        return null
      }
      vm.axios.post(api, {
        memberToken,
        token: tokenGenerator()
      }).then(res => {
        const userInfo = res.data.content
        if (userInfo.grade < grade) {
          alert('年級不足，請先玩其他年級的遊戲喔')
        } else {
          vm.$router.push(link)
        }
      })
    }
  }
}
</script>
