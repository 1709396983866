<template>
  <ShareNetwork
    network="facebook"
    :url="'https://www.richkid.com.tw/' + currentPath"
    :title="title"
  >
    <a class="icon-rounded-circle-small bg-info" href="javascript:void(0)">
      <i class="fa fa-facebook text-white" aria-hidden="true" style="margin-top: 6px;"></i>
    </a>
  </ShareNetwork>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'FBShareButton',
  data () {
    return {
      title: ''
    }
  },
  computed: {
    currentPath () {
      return this.$route.path
    }
  },
  created () {
    const vm = this
    const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/detail`
    const id = vm.$route.params.id

    vm.axios.post(api, {
      token: tokenGenerator(),
      id: id
    })
      .then((res) => {
        vm.title = res.data.content.title
      })
  }
}
</script>
