<template>
  <div>
    <a
      :href="
        `https://social-plugins.line.me/lineit/share?url=${domain +
          currentPath}`
      "
      target="_blank"
      class="btn-line"
    >
      <span class="d-block" style="transform: scale(0.8)"> LINE </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'LineShareButton',
  computed: {
    currentPath () {
      return this.$route.path
    },
    domain () {
      return 'https://www.richkid.com.tw'
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-line {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 18px;
  background-color: #5aba0c;
  color: white;
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  padding: 5px 0;
}
</style>
